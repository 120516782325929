import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ employee confidentiality policy.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Elements</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Disciplinary Consequences</AnchorLink>
    </AnchorLinks>
    <h2>{`Purpose`}</h2>
    <p>{`We designed our company confidentiality policy to explain how we expect our employees to treat confidential information. Employees will unavoidably receive and handle personal and private information about clients, partners and our company. We want to make sure that this information is well-protected.`}</p>
    <p>{`We must protect this information for two reasons. It may:`}</p>
    <ul>
      <li parentName="ul">{`Be legally binding (e.g. sensitive customer data.)`}</li>
      <li parentName="ul">{`Constitute the backbone of our business, giving us a competitive advantage (e.g. business processes.)`}</li>
    </ul>
    <h2>{`Scope`}</h2>
    <p>{`This policy affects all employees, including board members, investors, contractors and volunteers, who may have access to confidential information.`}</p>
    <h2>{`Policy elements`}</h2>
    <p>{`Confidential and proprietary information is secret, valuable, expensive and/or easily replicated. Common examples of confidential information are:`}</p>
    <ul>
      <li parentName="ul">{`Unpublished financial information`}</li>
      <li parentName="ul">{`Data of Customers/Partners/Vendors`}</li>
      <li parentName="ul">{`Patents, formulas or new technologies`}</li>
      <li parentName="ul">{`Customer lists (existing and prospective)`}</li>
      <li parentName="ul">{`Data entrusted to our company by external parties`}</li>
      <li parentName="ul">{`Pricing/marketing and other undisclosed strategies`}</li>
      <li parentName="ul">{`Documents and processes explicitly marked as confidential`}</li>
      <li parentName="ul">{`Unpublished goals, forecasts and initiatives marked as confidential`}</li>
    </ul>
    <p>{`Employees may have various levels of authorized access to confidential information.`}</p>
    <p>{`What employees should do:`}</p>
    <ul>
      <li parentName="ul">{`Lock or secure confidential information at all times`}</li>
      <li parentName="ul">{`Shred confidential documents when they’re no longer needed`}</li>
      <li parentName="ul">{`Make sure they only view confidential information on secure devices`}</li>
      <li parentName="ul">{`Only disclose information to other employees when it’s necessary and authorized`}</li>
      <li parentName="ul">{`Keep confidential documents inside our company’s premises unless it’s absolutely necessary to move them`}</li>
    </ul>
    <p>{`What employees shouldn’t do:`}</p>
    <ul>
      <li parentName="ul">{`Use confidential information for any personal benefit or profit`}</li>
      <li parentName="ul">{`Disclose confidential information to anyone outside of our company`}</li>
      <li parentName="ul">{`Replicate confidential documents and files and store them on insecure devices`}</li>
    </ul>
    <p>{`When employees stop working for our company, they’re obliged to return any confidential files and delete them from their personal devices.`}</p>
    <h3>{`Confidentiality Measures`}</h3>
    <p>{`We’ll take measures to ensure that confidential information is well protected. We’ll:`}</p>
    <ul>
      <li parentName="ul">{`Store and lock paper documents`}</li>
      <li parentName="ul">{`Encrypt electronic information and safeguard databases`}</li>
      <li parentName="ul">{`Ask employees to sign non-compete and/or non-disclosure agreements (NDAs)`}</li>
      <li parentName="ul">{`Ask for authorization by senior management to allow employees to access certain confidential information`}</li>
    </ul>
    <h2>{`Exceptions`}</h2>
    <p>{`Confidential information may occasionally have to be disclosed for legitimate reasons. Examples are:`}</p>
    <ul>
      <li parentName="ul">{`If a regulatory body requests it as part of an investigation or audit`}</li>
      <li parentName="ul">{`If our company examines a venture or partnership that requires disclosing some information (within legal boundaries)
In such cases, employees involved should document their disclosure procedure and collect all needed authorizations. We’re bound to avoid disclosing more information than needed.`}</li>
    </ul>
    <h2>{`Disciplinary Consequences`}</h2>
    <p>{`Employees who don’t respect our confidentiality policy will face disciplinary and, possibly, legal action.`}</p>
    <p>{`We’ll investigate every breach of this policy. We’ll terminate any employee who willfully or regularly breaches our confidentiality guidelines for personal profit. We may also have to punish any unintentional breach of this policy depending on its frequency and seriousness. We’ll terminate employees who repeatedly disregard this policy, even when they do so unintentionally.`}</p>
    <p>{`This policy is binding even after separation of employment.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      